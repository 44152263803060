import React, { Component } from 'react';
import placeData, {makeFlatPlaceIndex} from '../data/placeData.js';
import AccordionList from '../components/AccordionList/AccordionList.js';
import "../styles/World.css";
import Layout from '../components/Layout.js';

let placeName = null;
let flatPlaceIndex = makeFlatPlaceIndex(placeData);

function Place({item, deepIndex}) {
  function renderImg() {
    if (!item.image) return null;
    return (<img className="placeConcept" alt="" src={item.image}/>)
  }

  function shouldExpandFolder(folder, folderIx) {
    if (typeof (placeName) !== 'string') return false;

    let targetPlaces = flatPlaceIndex[placeName].split(",");
    let thisPlace = deepIndex.split(",");
    thisPlace.push(folderIx.toString());
    let match = true;

    if (thisPlace.length > targetPlaces.length) return false;

    for (let i = 0; i < targetPlaces.length; ++i) {
      if (targetPlaces[i] !== thisPlace[i]) {
        match = false;
      }
    }

    return match;
  }

  function renderPlaces() {
    if (!item.places) return null;
    return (<AccordionList list={item.places} deepIndex={deepIndex} shouldExpand={shouldExpandFolder} SlotComponent={Place}/>)
  }

  return (
    <div className="placeWrapper">
      {renderImg()}
      <p className="placeDesc">{item.description}</p>
      {renderPlaces()}
    </div>
  )
}

class PlaceList extends Component {
  constructor(props) {
    super(props);
    //Terrible query parsing...
    placeName = (props.location.search) ? props.location.search.replace('?place=', '') : null;
  }

  shouldExpandFolder(folder, folderIx) {
    if (typeof (placeName) !== 'string') return false;

    return (flatPlaceIndex[placeName] && flatPlaceIndex[placeName].split(",")[0] && flatPlaceIndex[placeName].split(",")[0] === folderIx.toString());
  }

  componentDidMount() {
    if (typeof (placeName) === 'string') {
      window.setTimeout(() => {
        const targetEl = document.getElementById(placeName);
        if (targetEl) {
          document.documentElement.scrollTop = targetEl.getBoundingClientRect().y;
        }
      }, 40);
    }
  }

  render() {
    return (
        <Layout>
          <div className="content">
            <h1 className="placeLabel">Maydijira</h1>
            {/*<img className="mainMap" alt="Map of the world of Maydijira" src={map}/>*/}
            <AccordionList list={placeData} deepIndex={-1} shouldExpand={this.shouldExpandFolder} SlotComponent={Place}/>
          </div>
        </Layout>
    );
  }
}

export default PlaceList;


/*
 Needed validation: Place names should be unique and not contain ",", test makeFlatPlaceIndex
 */