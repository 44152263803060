// import { images } from './conceptArt';


export function makeFlatPlaceIndex(placeData) {
  let flatPlaces = [];
  let allOpenFolders = [];

  function addToFlatPlaces(name, value) {
    if (flatPlaces[name]) {
      throw new Error("Place names must be unique");
    }
    flatPlaces[name] = value;
  }

  function recursiveSearch(list, folderIx) {
    allOpenFolders.push(folderIx);
    addToFlatPlaces(list.name, allOpenFolders.join(','));
    list.places.forEach((place, placeIx) => {
      if (place.places) {
        recursiveSearch(place, placeIx);
      } else {
        addToFlatPlaces(place.name, allOpenFolders.join(',') + "," + placeIx);
      }
    });
    allOpenFolders.pop();
  }

  placeData.forEach(recursiveSearch);
  return flatPlaces;
}

export default [{
    name: "General Information",
    places: [],
    description: `The continent of Maydijira is ringed by tall icebergs that never melt, collectively called "The Windbreak". Sailors who have attempted to pass it have never gotten very far, as the sea beyond is very dangerous. It is not known how any of the current peoples arrived there, but it is well-documented that at the time most of the races arrived, Maydijira was only occupied by a sparse population of nomadic Kilonji tribes. Old ruins of great Kilonji cities remain as evidence that shortly before that time, an advanced Kilonji empire had existed and that the scattered tribes were all that remained after its collapse.

Maydijira has three distinct magic systems. The most common one, Wizardry, occurs in every race; but with far greatest frequency in human kingdoms. It is based around a language of power and is the most effective for enchanting because any object can be the focus of a Wizardic enchantment. Their source of power is external and never exhausts, but the access each Wizard enjoys is finite. The greater the spell, the more it saps their potential to use magic at a future date, so Wizards are powerfully motivated to keep their spells efficient.

Elven magic grows stronger with age and can be boosted by "harmonizing" with certain focal points in nature. Although Elven magic's active uses are limited to the senses, it has strong passive effects on the surrounding environment which the user cannot control. Useful for sensing emotions and the presence of magic, elven magic can be a powerful tool for advanced users and has even been known to show the future. Elven magic tends to be at a disadvantage compared to the other magics, motivating some elves to intermarry into other races despite the difference in life-spans. However, it is the most unpredictable or least understood kind of magic and can bestow particularly unexpected abilities when combined with other magic types.

The Kilonji race enjoys a very high percentage of magic-users to non-magic users in its population. The power source for Kilonji magic is internal and tends to exhaust quickly, though it is easily restored. Kilonji magic typically gives the user power over only one energy type. The Kilonji enchanters, called Arysenthines because of their link to a magic Arysenth gemstone which expands their reserves and abilities, are the exceptions. Becoming an Arysenthine carries a serious risk of death, but the power passes on to a limited number of descendants. The Arysenthines give the Kilonji an edge over other races in battle, contributing to a history of the Kilonji being at war with a coalition of the other races. The Crown-Magic system of negating magic was designed primarily to reign in the Arysenthines.

Maydijira is divided into six kingdoms and distinct races. Two, Lendurra and Kricoi, are populated by closely related human races, and the kingdoms of Nendeu and Stelmeri are subgroups of the elves. Duendel is populated by dwarves, and Kivelen is a Kilonji nation.`
  }, {
    name: "Lendurra",
    description: "Lendurra's economy is based on its access to the largest river system in Maydijira. The land is very fertile and suitable for irrigation, leading to a heavy focus on agriculture and trade. Most Lendurrans live simply and value community highly.",
    places: [
      {
        name: "Mentoris",
        description: "Capital of Lendurra and home to it's monarch, Queen Lifhal. This bustling port city is the site of several popular festivals central to Lendurran cultural life."
      }
    ]
  }, {
    name: "Kricoi",
    description: "The Kricoians live farthest south in Maydijira where the climate is the hottest and much of the land is dry and arid. Among the urban population are many fishermen, scholars, and craftsmen. Their brightly dyed fabrics and glazed pottery are highly prized exports. The remote Kricoi highlands are home to many unusual creatures and as well as to nomadic clans that tame and breed them.",
    places: [],
    // image: images["kivThrone"]
  }, {
    name: "Nendeu",
    description: "Nendeu is home to the Dark- or Moon-Elves. Located between the territory of powerful neighbors, Nendeu has a long martial history. Their archers are feared throughout Maydijira for their skill, and their swords are rumored to have mystical properties to defend their wielder, although that has never been proven. Most Dark-Elf cities are hidden in the tree-tops of the tall forests of Nendeu and are connected by wooden ramps and walkways.",
    places: []
  }, {
    name: "Stelmeri",
    description: "Stelmeri is home to the Fair- or Sun-Elves. The Fair-Elves have an especially strong effect on the growth and vitality of nearby plants and are herbalists and healers of exceptional potency. Their cities are mostly nestled in the sun-bathed white cliffs of Maydijira's southern coast along the Ennuil River. Their exquisite glassmaking is a valued export.",
    places: []
  }, {
    name: "Duendel",
    description: "Duendel is a mountainous region, riddled with miles of connected cave systems. The Dwarves are quite private and few visitors have seen their sprawling underground cities, but their splendor is legendary. In the heart of the mountains, the Dwarves run giant forges and smith metals and gemstones into monumental works. The Dwarves supply the best armor in Maydirira, but in general, their economy is self-sustaining and they are rarely seen outside their homeland.",
    places: []
  }, {
    name: "Kivelen",
    description: "Kivelen is the coldest kingdom thanks to its northern location and high elevation. The Kilonji are renowned scientists and architects. Since joining the Crown-Magic pact, Kivelen has enjoyed good relations with their neighbors, except for in the recent decade in which they have become strained.",
    places: []
  }
]